import * as loginStates from '@/store/static/login-states'
import {mapGetters} from 'vuex'
import {CHANGE_PASSWORD_PAGE, MULTI_FACTOR_AUTH_PAGE} from '@/router/route-names'
import qs from 'qs'

export default {
    data: () => ({
        remember: false,
        redirectUrlWithQuery: ''
    }),
    methods: {
        handleLoginResponse (response) {
            const vm = this
            switch (response.state) {
                case loginStates.FORCE_PASSWORD_CHANGE:
                    return vm.forceChangePassword(response)
                case loginStates.MULTI_FACTOR_AUTH:
                    return vm.multiFactorAuth(response)
                case loginStates.REDIRECT:
                    return vm.redirect({
                        ...response,
                        remember: vm.remember ? 1 : 0
                    })
            }
        },
        forceChangePassword (response) {
            const vm = this
            vm.$router.push({
                name: CHANGE_PASSWORD_PAGE,
                params: {
                    userId: response.user_id,
                    code: response.data.code
                },
                query: {
                    ...vm.$route.query,
                    email_address: response.email_address,
                    account_code: response.account_code
                }
            })
        },
        multiFactorAuth (response) {
            const vm = this
            vm.$router.push({
                name: MULTI_FACTOR_AUTH_PAGE,
                params: {
                    userId: response.user_id,
                    code: response.data.code
                },
                query: {
                    ...vm.$route.query,
                    email_address: response.email_address,
                    account_code: response.account_code
                }
            })
        },
        redirect (response) {
            const vm = this
            const query = {...response.data, ..._.omit(response, 'data')}
            let redirectUrl = vm.redirectUrl

            const params = new URLSearchParams(window.location.search)

            if (params.has('redirectUrl')) {
                redirectUrl = params.get('redirectUrl')
            }

            if (redirectUrl) {
                const url = new URL(redirectUrl)

                Object.keys(query).forEach(key => {
                    url.searchParams.append(key, query[key])
                })

                vm.redirectUrlWithQuery = url.href
                window.location.replace(url.href)
            }
        }
    },
    computed: mapGetters('auth', ['redirectUrl'])
}
