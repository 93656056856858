<template>
    <centered-layout>
        <w-auth-login-form
            :custom-rules="[validationRules.generic.required]"
            :errors="loginErrors"
            :loading="loading"
            :logo-src="clientLogo"
            :show-remember-me="false"
            :show-username="false"
            :title="title"
            @submit="submit"
            allow-autocomplete
            :show-account-code="false"
            show-email-address
            v-if="!loggedIn"
        >
            <template slot="actions">
                <router-link :to="{name: forgottenPasswordPage}" class="left">
                    Forgot password?
                </router-link>
            </template>
        </w-auth-login-form>
        <w-form-message :logo-path="clientLogo" :title="title" v-else>
            <p>You are now being redirected. If you are not redirected automatically,
                <a :href="redirectUrlWithQuery">click here.</a>
            </p>
            <v-progress-linear indeterminate></v-progress-linear>
        </w-form-message>
    </centered-layout>
</template>

<style>
    .v-alert {
        text-align: left;
    }
</style>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {FORGOTTEN_PASSWORD_PAGE} from '@/router/route-names'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import LoginMixin from '@/mixins/login-mixin'
    import WValidationMixin from '@wf/mixins/forms/w-validation-mixin'
    import WFormMessage from '@wf/components/WFormMessage'
    import WAuthLoginForm from '@wf/components/WAuthLoginForm'

    export default {
        name: 'LoginPage',
        mixins: [LoginMixin, WValidationMixin],
        metaInfo () {
            const vm = this
            return {
                title: this.loading ? 'Authorising Login' : vm.title
            }
        },
        data: () => ({
            loading: false,
            loggedIn: false,
            loginErrors: [],
            forgottenPasswordPage: FORGOTTEN_PASSWORD_PAGE
        }),
        components: {
            CenteredLayout,
            WFormMessage,
            WAuthLoginForm
        },
        methods: {
            ...mapActions('auth', ['login']),
            submit (data) {
                const vm = this
                vm.loading = true
                vm.login(data).then((response) => {
                    vm.handleLoginResponse(response)
                    vm.loading = false
                    vm.loggedIn = true
                    vm.loginErrors = []
                }, (response) => {
                    vm.loginErrors = response.errors
                    vm.loading = false
                })
            }
        },
        computed: {
            ...mapGetters('auth', ['clientName', 'clientLogo']),
            title () {
                return `Login - ${this.clientName}`
            }
        }
    }
</script>
